<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mb-5">
                <label>{{ $t('expenses_income_transaction.currency') }}</label>
                <select name="" id="currency_id" v-model="filters.f_currencies_ids" class="custom-select">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.doc_type') }}</label>
                <select name="" id="status" v-model="doc_type" @input="changeTypeObj" class="custom-select">
                  <option v-for="row in doc_type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
              </div>


              <template v-if="doc_type == 'Customer'">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('expenses_income_transaction.customer') }}</label>
                  <multiselect
                      id="customer_id"
                      v-model="doc_type_obj"
                      :placeholder="$t('expenses_income_transaction.customer')"
                      label="fullname"
                      track-by="id"
                      :options="customers"
                      :multiple="false"
                      @input="getIds('customers')"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getCustomers($event)">
                  </multiselect>
                </div>
              </template>
              <template v-if="doc_type == 'Supplier'">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('expenses_income_transaction.supplier') }}</label>
                  <multiselect
                      v-model="doc_type_obj"
                      :placeholder="$t('expenses_income_transaction.supplier')"
                      label="name"
                      track-by="id"
                      :options="suppliers"
                      :multiple="false"
                      @input="getIds('suppliers')"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getSuppliers($event)">
                  </multiselect>
                </div>
              </template>
              <template v-if="doc_type == 'Employee'">
                <div class="col-lg-6 mb-5">
                  <label for="employee_id">{{ $t('expenses_income_transaction.employee') }}</label>
                  <multiselect v-model="doc_type_obj"
                               :placeholder="$t('expenses_income_transaction.employee')"
                               label="full_name"
                               track-by="id"
                               :options="employees"
                               :multiple="false"
                               @input="getIds('employees')"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getEmployees($event)">
                  </multiselect>

                </div>
              </template>

              <!--              <div class="col-lg-6 mb-5">-->
              <!--                <label>{{ $t('expenses_income_transaction.payment_method') }}</label>-->
              <!--                <select name="" id="payment_method" v-model="filters.payment_method" class="custom-select">-->
              <!--                  <option v-for="row in payment_methods" :value="row.id" :key="row.id">{{ row.title }}</option>-->
              <!--                </select>-->
              <!--              </div>-->
              <!--              <div class="col-lg-6 mb-5" v-if="payment_method_type == 'bank'">-->
              <!--                <label>{{ $t('expenses_income_transaction.bank') }}</label>-->
              <!--                  <multiselect v-model="bank"-->
              <!--                               :placeholder="$t('expenses.bank')"-->
              <!--                               label="name"-->
              <!--                               track-by="id"-->
              <!--                               :options="banks"-->
              <!--                               :multiple="false"-->
              <!--                               :taggable="false"-->
              <!--                               :show-labels="false"-->
              <!--                               :show-no-options="false"-->
              <!--                               :show-no-results="false"-->
              <!--                               @input="useBank"-->
              <!--                               @search-change="getBanks($event)">-->
              <!--                  </multiselect>-->
              <!--              </div>-->
              <!--              <div class="col-lg-6 mb-5" v-else-if="payment_method_type == 'treasury'">-->
              <!--              <label>{{ $t('expenses_income_transaction.treasury') }}</label>-->
              <!--              <multiselect v-model="treasury"-->
              <!--                           :placeholder="$t('expenses.treasury')"-->
              <!--                           label="name"-->
              <!--                           track-by="id"-->
              <!--                           :options="treasuries"-->
              <!--                           :multiple="false"-->
              <!--                           :taggable="false"-->
              <!--                           :show-labels="false"-->
              <!--                           :show-no-options="false"-->
              <!--                           :show-no-results="false"-->
              <!--                           @input="useTreasury">-->
              <!--              </multiselect>-->
              <!--            </div>-->
              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.treasury') }}</label>
                <multiselect v-model="treasury"
                             :placeholder="$t('expenses.treasury')"
                             label="name"
                             track-by="id"
                             :options="treasuries"
                             :multiple="false"
                             @input="getIds('treasuries')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('expenses_income_transaction.branch') }}</label>
                <multiselect v-model="branch"
                             :placeholder="$t('expenses_income_transaction.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="false"
                             @input="getIds('branches')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <!--              <div class="col-lg-6 mb-5">-->
              <!--                <label>{{ $t('expenses_income_transaction.class') }}</label>-->
              <!--                <select name="" id="payment_method" v-model="filters.class" class="custom-select">-->
              <!--                  <option :value="null" >{{ $t('all') }}</option>-->
              <!--                  <option :value="1" >{{ $t('expenses_income_transaction.doc_in') }}</option>-->
              <!--                  <option :value="2">{{ $t('expenses_income_transaction.doc_out') }}</option>-->
              <!--                </select>-->
              <!--              </div>-->

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('expenses_income_transaction.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('expenses_income_transaction.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_date') }}</b-button>
                    <b-button @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_month') }}</b-button>
                    <b-button @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_year') }}</b-button>
                    <b-button @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.financial_year') }}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.yesterday') }}</b-button>
                    <b-button @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_month') }}</b-button>
                    <b-button @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_year') }}</b-button>
                  </b-button-group>

                </div>
              </div>

              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab :title="$t('summery')" active>
            <div class="row mb-10">
              <div class="col-md-12 mt-10" id="summaryTableTitle">
                <h4 class="text-center">{{ $t('sales_by_client_report.sales_revenue_report') }}</h4>
                <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
              </div>
            </div>
            <div class="row mb-10">
              <div class="col-12">
                <div class="table-responsive">
                  <div class="row justify-content-end p-4">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">

                      <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('excel') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTable')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printPDFData('summaryTable', 'pdf')">
                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="getAllDataForExportSummery('sectionForExportSummary', 'excel')">
                        <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                      </button>

                    </b-dropdown>
                  </div>
                  <table class="table table-bordered" id="summaryTable">
                    <thead>
                    <tr>
                      <th>{{ $t('income_report.' + periodLabel) }}</th>
                      <th>{{ $t('expenses_income_transaction.amount') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row2, index2) in data_report_summary" :key="'a'+getRandom()+index2">
                      <td>{{ row2.label }}</td>
                      <td>{{ row2.total_amount }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td><b>{{ $t('expenses_income_transaction.total') }}</b></td>
                      <th>{{ summary_total_sum ? summary_total_sum : 0 }}</th>
                    </tr>
                    </tfoot>
                  </table>
                </div>

              </div>
              <div class="col-12 text-center" v-if="page">
                <button class="btn btn-warning" @click="loadMore">
                  <p class="mb-0">
                    <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                    {{ $t('load_more') }}
                  </p>
                </button>
              </div>

            </div>
          </b-tab>
          <b-tab :title="$t('details')">
            <div class="mt-3">
              <div class="row mb-10">
                <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                  <h4 class="text-center">{{ $t('MENU.income_expenses_report') }}</h4>
                  <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                </div>
              </div>
              <div class="row mb-10">
                <div class="col-12" v-if="data_report_details">
                  <div class="table-responsive">
                    <div class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                        <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails')">
                          <span><i class="fa fa-file-pdf"></i> <span>{{ $t('excel') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="printData('summaryTableDetails')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="printPDFData('summaryTableDetails', 'pdf')">
                          <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="getAllDataForExport('sectionForExportDetails', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>
                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="summaryTableDetails">
                      <thead>
                      <tr>
                        <th>{{ $t('id') }}</th>
                        <th>{{ $t('expenses_income_transaction.code') }}</th>
                        <th>{{ $t('expenses_income_transaction.date') }}</th>
                        <th>{{ $t('expenses_income_transaction.payment_channel') }}</th>
                        <th>{{ $t('expenses_income_transaction.payment_method') }}</th>
                        <th>{{ $t('expenses_income_transaction.doc_type_lable') }}</th>
                        <th>{{ $t('expenses_income_transaction.doc_type') }}</th>
                        <th>{{ $t('expenses_income_transaction.class') }}</th>
                        <th>{{ $t('expenses_income_transaction.currency') }}</th>
                        <th>{{ $t('expenses_income_transaction.amount') }}</th>

                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(row, index) in data_report_details">
                        <tr :key="'a'+index">
                          <td colspan="10">{{ row.label }}</td>
                        </tr>
                        <tr v-for="(row2, index2) in row.items" :key="'a'+getRandom()+index2">
                          <td>{{ row2.id }}</td>
                          <td>{{ row2.code }}</td>
                          <td>{{ row2.expenses_date }}</td>
                          <td>{{ row2.type_label }} - {{ row2.type_name }}</td>
                          <td>{{ row2.payment_method_name }}</td>
                          <td>{{ row2.doc_type_label }}</td>
                          <td>{{ row2.doc_type_name }}</td>
                          <td>{{ row2.class_name }}</td>
                          <td>{{ row2.currency_name }}</td>
                          <td>{{ row2.amount }}</td>
                        </tr>
                        <tr :key="'b'+index">
                          <td colspan="9">{{ $t('income_report.total') }}</td>
                          <td>{{ row.total_amount }}</td>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="9"><b>{{ $t('income_report.total') }}</b></td>
                        <th>{{ details_total_sum ? details_total_sum : 0 }}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-warning" @click="loadMoreDetails">
                  <p class="mb-0">
                    <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                    {{ $t('load_more') }}
                  </p>
                </button>
              </div>
            </div>
          </b-tab>
          <template #tabs-end>
            <li class="nav-item  d-flex my-auto">
              <select name="" id="" v-model="filters.group_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none">
                <option :value="null" :disabled="true">{{ $t('select_filter') }}</option>
                <option v-for="(row, index) in period_list" :value="row.slug" :key="'period'+index">{{ row.title }}</option>
              </select>

            </li>
          </template>
          <!-- <b-tab> -->
          <!-- </b-tab> -->
        </b-tabs>


      </div>
    </div>
    <!--end::supplier-->
    <div class="export-container">
      <div class="section-for-export">
        <!--        sectionForExportSummary-->
        <table class="table table-bordered" id="sectionForExportSummary">
          <thead>
          <tr>
            <th>{{ $t('income_report.' + periodLabel) }}</th>
            <th>{{ $t('expenses_income_transaction.amount') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row2, index2) in dataListSummery" :key="'attt'+getRandom()+index2">
            <td>{{ row2.label }}</td>
            <td>{{ row2.total_amount }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td><b>{{ $t('expenses_income_transaction.total') }}</b></td>
            <th>{{ dataList_total_sumSummery ? dataList_total_sumSummery : 0 }}</th>
          </tr>
          </tfoot>
        </table>
        <!--        sectionForExportDetails-->
        <table class="table table-bordered" id="sectionForExportDetails">
          <thead>
          <tr>
            <th>{{ $t('id') }}</th>
            <th>{{ $t('expenses_income_transaction.code') }}</th>
            <th>{{ $t('expenses_income_transaction.date') }}</th>
            <th>{{ $t('expenses_income_transaction.payment_channel') }}</th>
            <th>{{ $t('expenses_income_transaction.payment_method') }}</th>
            <th>{{ $t('expenses_income_transaction.doc_type_lable') }}</th>
            <th>{{ $t('expenses_income_transaction.doc_type') }}</th>
            <th>{{ $t('expenses_income_transaction.class') }}</th>
            <th>{{ $t('expenses_income_transaction.currency') }}</th>
            <th>{{ $t('expenses_income_transaction.amount') }}</th>

          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataList">
            <tr :key="'a'+index">
              <td colspan="10">{{ row.label }}</td>
            </tr>
            <tr v-for="(row2, index2) in row.items" :key="'a'+getRandom()+index2">
              <td>{{ row2.id }}</td>
              <td>{{ row2.code }}</td>
              <td>{{ row2.expenses_date }}</td>
              <td>{{ row2.type_label }} - {{ row2.type_name }}</td>
              <td>{{ row2.payment_method_name }}</td>
              <td>{{ row2.doc_type_label }}</td>
              <td>{{ row2.doc_type_name }}</td>
              <td>{{ row2.class_name }}</td>
              <td>{{ row2.currency_name }}</td>
              <td>{{ row2.amount }}</td>
            </tr>
            <tr :key="'b'+index">
              <td colspan="9">{{ $t('income_report.total') }}</td>
              <td>{{ row.total_amount }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr>
            <td colspan="9"><b>{{ $t('income_report.total') }}</b></td>
            <th>{{ dataList_total_sum ? dataList_total_sum : 0 }}</th>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'IncomeExpensesReport'"></report-main-header>
      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";

export default {
  name: "index-income-expenses-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/expenses_incomes_report/details',
      mainRouteSummery: '/reports/expenses_incomes_report/summary',
      mainRouteDependency: 'base/dependency',

      currency_name: null,
      currencies: [],
      treasuries: [],
      period_list: [

        {title: this.$t('income_report.daily'), slug: 'daily'},
        {title: this.$t('income_report.weekly'), slug: 'weekly'},
        {title: this.$t('income_report.monthly'), slug: 'monthly'},
        {title: this.$t('income_report.yearly'), slug: 'yearly'},

        {title: this.$t('income_report.customer'), slug: 'customer'},
        {title: this.$t('income_report.supplier'), slug: 'supplier'},
        {title: this.$t('income_report.user'), slug: 'user'},
        {title: this.$t('income_report.treasury'), slug: 'treasury'},
        {title: this.$t('income_report.branch'), slug: 'branch'},

        {title: this.$t('income_report.doc_type'), slug: 'doc_type'},
        {title: this.$t('income_report.type'), slug: 'type'},
        // {title: this.$t('income_report.expenses_date'), slug: 'expenses_date'},


      ],
      data_line: [],
      data_report_details: [],
      details_total_sum: 0,

      data_report_summary: [],
      summary_total_sum: 0,

      dataList: [],
      dataList_total_sum: 0,
      dataListSummery: [],
      dataList_total_sumSummery: 0,

      filters: {
        from_date: null,
        to_date: null,
        doc_type_id: null,
        group_by: 'daily',
        payment_method: null,
        type_id: null,
        type_type: null,
        class: null,

        period: null,
        range: null,
        f_time_period: 0,

        f_customers_ids: null,
        f_suppliers_ids: null,
        f_users_ids: null,
        f_treasuries_ids: null,
        f_branches_ids: null,
        f_currencies_ids: null,
      },
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,

      treasury: null,


      doc_type_list: [],
      doc_type_obj: null,
      suppliers: [],
      customers: [],
      employees: [],
      banks: [],
      payment_methods: [],
      bank: null,
      payment_method_type: null,
      periodLabel: 'daily',
      branch: null,
      branches: [],
      currency_default_id: null,
      doc_type: null,
    }
  },
  watch: {
    // doc_type_obj: function (val) {
    //   if (val && val.id)
    //     this.filters.doc_type_id = val.id;
    //   else
    //     this.filters.doc_type_id = null;
    // },
    'filters.payment_method': function (val) {
      this.payment_method_type = null;
      if (val) {
        let _method = this.payment_methods.find(row => row.id == val);
        if (_method) {
          this.payment_method_type = _method.cash_type;
        }
      }
    },
    'filters.group_by': function (val) {
      this.periodLabel = val;
      this.doFilter();
    },
  },
  beforeMount() {
    this.defaultDataForUser();
    this.getTreasuries();
    this.getCurrencies();
    this.docTypeListInit();
    // this.getPaymentMethods();
    this.getBranches();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.accounting_reports'), route: '/reports/accounting-reports'}, {title: this.$t("MENU.income_expenses_report")}]);
  },
  methods: {
    getIds(type) {
      let _types_list = ['customers', 'suppliers', 'employees'];
      if (_types_list.includes(type)) {
        this.filters.f_customers_ids = null;
        this.filters.f_suppliers_ids = null;
        this.filters.f_users_ids = null;
      }
      switch (type) {
        case 'customers':
          this.filters.f_customers_ids = this.doc_type_obj ? this.doc_type_obj.id : null;
          break;
        case 'suppliers':
          this.filters.f_suppliers_ids = this.doc_type_obj ? this.doc_type_obj.id : null;
          break;
        case 'employees':
          this.filters.f_users_ids = this.doc_type_obj ? this.doc_type_obj.id : null;
          break;
        case 'treasuries':
          this.filters.f_treasuries_ids = this.treasury ? this.treasury.id : null;
          break;
        case 'branches':
          this.filters.f_branches_ids = this.branch ? this.branch.id : null;
          break;
      }
    },

    changeIntervalType(action_type, range = null) {
      if (this.filters.period == action_type) {
        this.filters.period = null;
        this.filters.f_time_period = 0;
      } else {
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

    },

    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    doFilter() {
      this.getReportDetails();
      this.getReportSummary();
      // this.getReportChart();
    },
    resetFilter() {
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.doc_type_id = null;


      this.filters.payment_method = null;
      this.filters.type_id = null;
      this.filters.type_type = null;
      this.filters.class = null;

      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;

      this.filters.f_customers_ids = null;
      this.filters.f_suppliers_ids = null;
      this.filters.f_users_ids = null;
      this.filters.f_treasuries_ids = null;
      this.filters.f_branches_ids = null;
      this.filters.f_currencies_ids = this.currency_default_id;

      this.treasury = null;
      this.doc_type_obj = null;
      this.bank = null;
      this.payment_method_type = null;
      this.doc_type = null;
      this.branch = null;

      this.getReportDetails();
      this.getReportSummary();
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.filters.f_currencies_ids = response.data.data.currency_id;
        this.currency_default_id = response.data.data.currency_id;
      });
    },

    getTreasuries() {
      ApiService.get(this.mainRouteDependency + "/treasuries").then((response) => {
        this.treasuries = response.data.data;
      });
    },

    loadMore() {
      this.page = this.page + 1;
      this.getReportSummary();
    },
    loadMoreDetails() {
      this.page = this.page + 1;
      this.getReportDetails();
    },

    /*****
     * details
     */

    getReportDetails() {
      let _limit = (this.page ? this.page : 1) * 25;
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_report_details = response.data.data.data;
        this.details_total_sum = response.data.data.totals.total_amount ? response.data.data.totals.total_amount : 0;
      });
    },

    /**
     * summary
     */
    getReportSummary() {
      let _limit = (this.page_summery ? this.page_summery : 1) * 25;
      ApiService.get(this.mainRouteSummery, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data_report_summary = response.data.data.data;
        this.summary_total_sum = response.data.data.totals.total_amount ? response.data.data.totals.total_amount : 0;
      });
    },
    /**
     * export details
     * @param tableId
     * @param _export
     */
    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataList = response.data.data.data;
        this.dataList_total_sum = response.data.data.totals.total_amount ? response.data.data.totals.total_amount : 0;
        this.convertTableToExcelReport(tableId);
      });
    },
    /**
     * export summary
     * @param tableId
     * @param _export
     */
    getAllDataForExportSummery(tableId, _export) {
      ApiService.get(`${this.mainRouteSummery}`, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataListSummery = response.data.data.data;
        this.dataList_total_sumSummery = response.data.data.totals.total_amount ? response.data.data.totals.total_amount : 0;
        this.convertTableToExcelReport(tableId);
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    /**
     * print table
     * @param tableId
     */
    printData(tableId) {
      this.setReportLog('pdf', 'income and expenses report');

      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },

    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'income and expenses report');
      exportPDFInnerAction(tableId, type, this.$t('MENU.sales_revenue_report'), this.$t('MENU.sales_revenue_report'));

    },
    /**
     * Export table as an excel
     * @param tableId
     */
    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'income and expenses report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.sales_revenue_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    },

    docTypeListInit() {
      this.doc_type_list = [];
      if (this.$can('customers.list')) {
        this.doc_type_list.push({id: 'Customer', title: this.$t('expenses_income_transaction.customer')})
      }
      if (this.$can('suppliers.list')) {
        this.doc_type_list.push({id: 'Supplier', title: this.$t('expenses_income_transaction.supplier')})
      }
      if (this.$can('employees.list')) {
        this.doc_type_list.push({id: 'Employee', title: this.$t('expenses_income_transaction.employee')})
      }

      this.doc_type_list.push({id: 'Others', title: this.$t('expenses_income_transaction.others')})


    },

    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },

    async getCustomers(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      }
    },
    async getEmployees(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/employees`, {params: {filter: filter}}).then((response) => {
          this.employees = response.data.data;
        });
      }
    },
    getBanks(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
          this.banks = response.data.data;
        });
      } else {
        this.banks = [];
      }
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    useBank(obj) {
      this.filters.type_id = obj.id;
      this.filters.type_type = 'Bank';

    },
    useTreasury(obj) {
      this.filters.type_id = obj.id;
      this.filters.type_type = 'Treasuries';

    },
    changeTypeObj() {
      this.doc_type_obj = null;
      this.filters.f_customers_ids = null;
      this.filters.f_suppliers_ids = null;
      this.filters.f_users_ids = null;
    },
  },
};
</script>
